<template>
  <v-app style="position: relative">
    <d-navbar class="bg-navbar" style="z-index: 151; position:fixed; width: 100%; top: 0"/>
    <v-main style="margin-top: 68px;" :class="$vuetify.theme == 'dark'? '': 'bg'">
      <slot></slot>
    </v-main>
      <support-component></support-component>
  </v-app>

</template>

<script>

import SupportComponent from "@/components/SupportComponent.vue";

export default {
  name: "LayoutContent",
  components: {
      SupportComponent,
    DNavbar: () => import('@/components/DNavbar.vue')
  },
  data() {
    return {
      expanded: true
    }
  }
}
</script>

<style scoped>
.menu-icon {
  position: fixed;
  top: 20px;
  z-index: 153;
  cursor: pointer;
  transition: 0.2s;
}

.menu-icon:hover {
  opacity: 0.8;
}

.menu-icon:active {
  opacity: 0.6;
}


</style>
